
import { SIMCore_API } from '../../../Hosts';
import { reqGET, reqPOST, reqPUT } from '../../../Utils';
import { usarToken } from './UseToken';

export function network() {

  let url = SIMCore_API + '/' //Hosts.PRODUCTION === true ? Hosts.SIMCore_API : Platform.OS === 'ios' ? Hosts.SIMCore_API : Hosts.SIMCore_API_FAKE

  const login = async (data) => {

    let endpoint = 'login'
    let func_name = 'login_network:'

    try {
      let result = await reqPOST(url + endpoint, data)

      if (result.token === false) {
        return false
      }
      else {
        /*  console.log('result', result)
         console.log('result', result.user_info) */
        usarToken().saveToken(result, result.user_info)
        console.log('result', result.user_info)
        return true
      }

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
    }
    return false
  };

  const user_regist_check = async (data) => {

    let endpoint = 'app/entities/username/0000000/'
    let func_name = 'user_regist_check:'

    try {
      let exists = await reqGET(url + endpoint + data.email)

      if (exists.data[0].response === true) {

        return true
      }
      else {

        return false
      }
    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
    }
    return
  };

  const user_regist = async (data) => {

    let endpoint = 'app/entities'
    let func_name = 'user_regist:'

    try {

      await reqPOST(url + endpoint, data)

      return
    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return
  };

  const user_info = async (id_user) => {
    let endpoint = 'entities/0000000/' + id_user
    let func_name = 'user_info:'
    try {

      let info = await reqGET(url + endpoint)

      return info
    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return
  };

  const user_edit = async (data) => {

    let endpoint = 'entities'
    let func_name = 'user_edit:'

    try {

      await reqPUT(url + endpoint, data)
      // console.log(func_name, '[success]: ', aux)
      return
    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return
  };

  const post_historico_de_compras = async (compras) => {
    let endpoint = 'store/order'
    let func_name = 'set_historico_de_compras:'
    try {
      let dataCompras = await reqPOST(url + endpoint, compras)
      console.log(func_name, '[success]: ', dataCompras)

      return dataCompras

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return
  }



  const get_historico_de_compras = async (companycode, user_id) => { // historico de todas as encomendas
    let endpoint = 'store/order/client/' + companycode + '/' + user_id
    let func_name = 'get_historico_de_compras:'

    //console.log(func_name, '[codigo ]: ', companycode)

    console.log(func_name, '[id]: ', user_id)

    try {
      let dataCompras = await reqGET(url + endpoint)

      // console.log(func_name, '[success]: ', dataCompras)
      return dataCompras

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
    }
    return
  }
  const put_historico_de_compras = async (data) => {
    let endpoint = 'store/order'
    let func_name = 'put_historico_de_compras:'

    console.log(JSON.stringify(data, null, '\t'));
    try {
      let dataCompras = await reqPUT(url + endpoint, data)

      console.log(func_name, '[success]: ', dataCompras)
      return dataCompras

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return

  }

  const get_linguagem = async (company_code, id) => {
    let endpoint = 'entities/lang/' + company_code + '/' + id

    let func_name = 'get_linguagem:'

    /* console.log(func_name, '[codigo ]: ', company_code)
    console.log(func_name, '[id ]: ', id) */

    try {
      let data = await reqGET(url + endpoint)


      /* console.log(func_name, '[success]:', data) */
      return data

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
    }
    return



  }
  const get_termos_condicoes = async () => {
    let endpoint = 'web/content/0000000/53'
    let func_name = 'get_termos_condicoes:'

    try {
      let data = await reqGET(url + endpoint)
      return data

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
    }
    return

  }





  const set_qr = async (datas) => {
    let endpoint = 'qr/history' //+ data_id
    let func_name = 'set_qr:'
    // let aux = { id: data }

    console.log(func_name, '[codigo1111 ]: ', datas)
    try {
      let data = await reqPOST(url + endpoint, datas)
      console.log(func_name, '[succes11111111]:', data)

      return data

    }
    catch (error) {
      // console.log(func_name, '[error]: ', error)
      let obj_aux = {
        data: [
          {
            info: {
              classes: [],
              entries: [],
            }
          }
        ],
        status: ''

      }
      return obj_aux
    }

  }

  const get_qr = async (data_id) => {
    let endpoint = 'qr/history/get' //+ data_id
    let func_name = 'get_qr:'
    let aux = { id: data_id }

    //    console.log(func_name, '[codigo1111 ]: ', data_id)
    try {
      let data = await reqPOST(url + endpoint, aux)
      /* console.log(func_name, '[succes11111111]:', data)
      console.log(func_name, '[success11]:', data.data[0].info)
      console.log(func_name, '[success2]:', data.data[0].info.equipments.length)
      console.log(func_name, '[success3]:', data.data[0].info.classes.length)
      console.log(func_name, '[success4]:', data.data[0].info.entries.length)
      console.log(func_name, '[success5]:', data.data[0].info.experiencias.length)
 */
      return data

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
      let obj_aux = {
        data: [
          {
            info: {
              classes: [],
              entries: [],
            }
          }
        ],
        status: ''

      }
      return obj_aux
    }

  }
  const gerar_ref_de_multibanco = async (valor_da_encomenda, id) => {
    let metodo = 'mb';
    let endpoint = 'store/payment/checkout/'
    let func_name = 'gerar_ref_de_multibanco:'
    //let url_aux = 'https://temp.assec.pt/'


    // console.log(func_name, '[success]: ', valor_da_encomenda)


    //const body = { id_para_gerar_ref: id, valor: valor_da_encomenda, metodo_utilizado: metodo }
    let body = { key: id, value: valor_da_encomenda, method: metodo }
    console.log(func_name, '[success]: ', body)
    console.log(func_name, '[success]: ', url + endpoint)


    try {
      let info = await reqPOST(url + endpoint, body)
      //let info = await simpleAxiosGet(url_aux, endpoint)

      console.log(func_name, '[successooo]: ', info)
      return info
    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return
  }

  const gerar_ref_de_mbway = async (valor_da_encomenda, id, numero_de_telemovel) => {
    let metodo = 'mbw';
    let endpoint = 'store/payment/checkout/'
    let func_name = 'gerar_ref_de_mbway:'

    let body = { key: id, value: valor_da_encomenda, method: metodo, phone: numero_de_telemovel }
    console.log(func_name, '[success]: ', url + endpoint)

    console.log(func_name, '[success]: ', body)

    //let endpoint = 'repo/teste_easypay.php/' + metodo + '/' + valor_da_encomenda + '/' + numero_de_telemovel + '/' + id
    try {
      let info = await reqPOST(url + endpoint, body)

      console.log(func_name, '[success]: ', info)
      return info
    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return


  }
  const gerar_ref_de_cc = async (valor_da_encomenda, id) => {
    let metodo = 'cc';
    let endpoint = 'store/payment/checkout/'
    let func_name = 'gerar_ref_de_cc:'

    let body = { key: id, value: valor_da_encomenda, method: metodo }
    //console.log(func_name, '[success]: ', valor_da_encomenda)
    //console.log(func_name, '[success]: ', id)
    //console.log(func_name, '[success]: ', endpoint)

    //let endpoint = 'repo/teste_easypay.php/' + metodo + '/' + valor_da_encomenda + '/' + numero_de_telemovel + '/' + id
    try {
      let info = await reqPOST(url + endpoint, body)
      console.log(func_name, '[success]: ', info)
      return info
    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
      //return { data: { response: { method: { url: '' }, status: 'error' } }, status: 'error' }
    }
    return


  }
  const post_info_about_payment = async (body) => {
    let endpoint = 'store/purchase/payment/'
    let func_name = 'post_info_about_payment:'
    try {
      let info = await reqPOST(url + endpoint, body)
      return info
    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return


  }
  const get_horario_disponivel = async (companycode, inicial_data, end_data, id_prod) => {
    /*  console.log('companycode', companycode)
      console.log('inicial_data', inicial_data)
      console.log('end_data', end_data)
      console.log('id_prod', id_prod)   */
    let endpoint = 'goldenrock/event/range/category/' + companycode + '/' + inicial_data + '/' + end_data + '/' + id_prod
    let func_name = 'get_horario_disponivel:'
    // console.log('url + endpoint', url + endpoint) 

    try {
      let data = await reqGET(url + endpoint)

      //console.log(func_name, '[success]: ',data)
      return data

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
    }
    return
  }
  const get_categories = async () => {
    let endpoint = 'shop/categories/'
    let func_name = 'get_categories:'
    //console.log(func_name, '[success]: ', url + endpoint)

    try {
      let dataMap = await reqGET(url + endpoint)

      //console.log(func_name, '[success]: ', dataMap)
      return dataMap.data

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
      return []
    }
  }

  const get_produtos_by_codgrupo = async (codgrupo) => {

    let endpoint = 'platform/products/grupo/' + codgrupo
    let func_name = 'get_produtos_by_codgrupo:'
    try {
      let dataMap = await reqGET(url + endpoint)

      // console.log(func_name, '[success]: ', JSON.stringify(dataMap, null, '\t'))
      return dataMap

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return
  }
  const get_produtos_by_codgrupo_and_date = async (codgrupo, date) => {

    let endpoint = 'platform/products/grupo/' + codgrupo + '/date/' + date
    let func_name = 'get_produtos_by_codgrupo:'
    try {

      let dataMap = await reqGET(url + endpoint)

      // console.log(func_name, '[success]: ', JSON.stringify(dataMap, null, '\t'))
      return dataMap

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return
  }
  const get_produtos_by_id = async (id_prod) => {


    console.log('#################')
    console.log('#################')
    console.log('#################')
    console.log('#################')

    let endpoint = 'platform/products/' + id_prod
    let func_name = 'get_produtos_by_id:'
    //console.log('get_produtos_by_id func',await usarToken().getToken())
    try {
      console.log(func_name, '[success]: ',url + endpoint)

      let dataMap = await reqGET(url + endpoint)

      console.log(func_name, '[success]: ', JSON.stringify(dataMap, null, '\t'))
      return dataMap.data[0]

    }
    catch (error) {

      console.log(func_name, '[error]: ',error)

      console.error(func_name, '[error111]: ', error)
      console.error(func_name, '[error]: ',id_prod)

    }
    return
  }
  const get_events_incompativeis_to_cative = async (event_id) => {

    let endpoint = 'goldenrock/collision/' + event_id
    let func_name = 'get_events_incompativeis_to_cative:'
    console.log(func_name, '[antes]: ', event_id)
    console.log(func_name, '[antes]: ', url + endpoint)

    try {
      let dataMap = await reqGET(url + endpoint)

      console.log(func_name, '[success]: ', JSON.stringify(dataMap, null, '\t'))
      return dataMap

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return
  }
  const get_event_by_id = async (event_id) => {

    let endpoint = 'goldenrock/event/data/event_id/' + event_id
    let func_name = 'get_event_by_id:'
    // console.log(func_name, '[antes]: ', event_id)

    try {
      let dataMap = await reqGET(url + endpoint)

      // console.log(func_name, '[success]: ', JSON.stringify(dataMap, null, '\t'))
      return dataMap.data[0]

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return
  }
  const get_stock_by_id_event = async (event_id) => {

    let endpoint = 'stocks/id_event/' + event_id
    let func_name = 'get_stock_by_id_event:'
    // console.log(func_name, '[antes]: ', event_id)

    try {
      let dataMap = await reqGET(url + endpoint)

      // console.log(func_name, '[success]: ', JSON.stringify(dataMap, null, '\t'))
      return dataMap.data[0]

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)

    }
    return
  }
  const get_preco_by_id_da_lista_de_stock = async (id) => {

    let endpoint = 'goldenrock/event/tarifa/stock_old/' + id
    let func_name = 'get_preco_by_id_da_lista_de_stock:'
    /*  console.log(func_name, '[antes]: ', id)
     console.log(func_name, '[antes]: ', url + endpoint)
  */
    try {
      let dataMap = await reqGET(url + endpoint)

      //console.log(func_name, '[success]: ', JSON.stringify(dataMap, null, '\t'))
      return dataMap.data[0]

    }
    catch (error) {
      console.error(func_name, '[error]: ', error)
      console.error(func_name, '[antes]: ', id)
     // console.error(func_name, '[antes]: ', await usarToken().getToken())
      
      return []
    }
  //  return
  }
  const get_stocks_by_event_date_and_id_do_produto = async (id, event_date) => {

    let endpoint = 'stocks/' + event_date + '/' + id
    let func_name = 'get_stocks_by_event_date_and_id_do_produto:'

    try {
      let res = await reqGET(url + endpoint)
      if (parseInt(res.status) === 200) {
        // console.log(func_name + ' ' + JSON.stringify(res.data, null, '\t'))
        return res.data
      } else {
        return []
      }

    } catch (error) {
      console.log(func_name, '[error]: ', error)
      return []
    }
  }
  const get_stocks_by_id_da_linha_de_stock = async (id) => {

    let endpoint = 'stocks/id/' + id
    let func_name = 'get_stocks_by_id:'

    try {
      let res = await reqGET(url + endpoint)
      if (parseInt(res.status) === 200) {
        console.log(func_name + ' ' + JSON.stringify(res.data, null, '\t'))
        return res.data
      } else {
        return []
      }

    } catch (error) {
      console.error(func_name, '[error]: ', error)
      console.error(func_name, '[error]: ', id)
      return []
    }
  }
  const get_referencia = async (timestamp_id, company_code) => {
    let endpoint = 'store/purchase/payment/code/' + company_code
    let func_name = 'get_referencia:'
    let body = { code: timestamp_id }

    // console.log(func_name, '[codigo1 ]: ', timestamp_id)
    //console.log(func_name, '[codigo2 ]: ', company_code)
    //console.log(func_name, '[codigo3 ]: ', body)
    try {
      let data = await reqPOST(url + endpoint, body)
      //console.log(func_name, '[successss]:', data)
      return data

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
    }
    return
  }
  const solve_event_error = async (element, msg) => {
    let endpoint = 'error'
    let func_name = 'solve_event_error:'
    let body = {
      error: {
        msg: msg,
        object: element
      }
    }
    try {
      console.log(func_name, '[successss]:', body)

      let data = await reqPOST(url + endpoint, body)
      console.log(func_name, '[successss]:', data)
      return data

    }
    catch (error) {
      console.log(func_name, '[error]: ', error)
    }
    return
  }
  const get_timer_countdown = async () => {

    let endpoint = 'goldenrock/time'
    let func_name = 'get_timer_countdown:'

    try {
      let res = await reqGET(url + endpoint)
      //console.log(func_name + ' ' + JSON.stringify(res.data, null, '\t'))
      return res.data
      /*  if (parseInt(res.status) === 200) {
         console.log(func_name + ' ' + JSON.stringify(res.data, null, '\t'))
         return res.data
       } else {
         return []
       } */

    } catch (error) {
      console.log(func_name, '[error]: ', error)
      return {}
    }
  }
  const get_prods_cache_filter = async (array_field_to_filter) => {

    let endpoint = 'goldenrock/cache/get/data'
    let func_name = 'get_prods_cache_filter:'
   /*  console.log('url + endpoint', url + endpoint)
    console.log('array_field_to_filter', array_field_to_filter) */

    try {
      let res = await reqPOST(/* 'http://localhost:3005' */url + endpoint,({data: array_field_to_filter}))
      // console.log(func_name + ' ' + JSON.stringify(res, null, '\t'))

      if (parseInt(res.status) === 200) {

        return res.data
      } else {
        return []
      } 
    } catch (error) {
      console.log(func_name + '[error]: ', error)
      return []
    }
  }
  const get_prods_cache_filter_range = async (array_field_to_filter) => {

    let endpoint = 'goldenrock/cache/get/data/range'
    let func_name = 'get_prods_cache_filter:'
   /*  console.log('url + endpoint', url + endpoint)
    console.log('array_field_to_filter', array_field_to_filter) */

    try {
      let res = await reqPOST(/* 'http://localhost:3005' */url + endpoint,({data: array_field_to_filter}))
      // console.log(func_name + ' ' + JSON.stringify(res, null, '\t'))

      if (parseInt(res.status) === 200) {

        return res.data
      } else {
        return []
      } 
    } catch (error) {
      console.log(func_name + '[error]: ', error)
      return []
    }
  }


  return {
    login,
    user_regist_check,
    user_regist,
    user_info,
    user_edit,
    get_linguagem,
    get_termos_condicoes,
    gerar_ref_de_mbway,
    gerar_ref_de_cc,
    post_info_about_payment,
    post_historico_de_compras,
    get_horario_disponivel,
    get_categories,
    gerar_ref_de_multibanco,
    get_historico_de_compras,
    get_produtos_by_codgrupo,
    get_produtos_by_codgrupo_and_date,
    get_events_incompativeis_to_cative,
    get_produtos_by_id,
    get_event_by_id,
    get_stock_by_id_event,
    get_preco_by_id_da_lista_de_stock,
    get_stocks_by_event_date_and_id_do_produto,
    get_stocks_by_id_da_linha_de_stock,
    get_qr,
    set_qr,
    get_referencia,
    put_historico_de_compras,
    solve_event_error,
    get_timer_countdown, 
    get_prods_cache_filter,
    get_prods_cache_filter_range,

  }
}



